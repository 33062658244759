import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import media from '../styles/media';
import { Img } from '../styles/components';
import Modal from './modal';
import { showLg } from '../styles/mixins';
import { colors } from '../styles/variables';

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 220px;
    align-items: center;
    ${media.lg1`
      margin-top: 20px;
    `};
`;

const FullScreen = styled.span`
  background-image: url('/images/full-screen1.svg');
  width: 17px;
  height: 17px;
  display: block;
  position: absolute;
  bottom: 6px;
  right: 0;
  cursor: pointer;

  ${media.lg`
    display: none;
  `};
`;
const ImgWithHover = styled(Img)`
  opacity: ${props => props.isLoading ? '0' : '1'};
  transition: transform .2s ease-in;
  &:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
  ${media.md`
    margin-top: 4vh;
  `};

  ${media.lg1`
    margin-top: ${props => props.questionID === 22 ? '-25px' : '0'};
  `}
`;

const ImgWithHoverLg = styled(ImgWithHover)`
  ${showLg}
`;

const ImgModal = styled(Img)`
   ${media.lg`
  `}
`;

const ImgLg = styled(Img)`
  ${showLg}
`;



const LoaderDualRing = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: ${props=> props.visible ? 'inline-block' : 'none'};
  width: 80px;
&::after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: ${colors.colorPrimary} transparent ${colors.colorPrimary} transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

const Image = ({ image, questionID, ...props }) => {

  useEffect(() => {
   setIsLoading(true);
  }, [image])

  const [isLoading, setIsLoading] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ImageContainer>
        <ImgWithHover isLoading={isLoading} src={'/images/' + image} onLoad={() => setIsLoading(false)} alt={image.slice(0, image.length - 4)}  questionID={questionID} onClick={() => {setIsModalOpen(true)}}/> 
        { questionID === 20 &&
          <ImgWithHoverLg src={'/images/close-your-eyes-desktop.png'} onLoad={() => setIsLoading(false)} alt={image.slice(0, image.length - 4)} onClick={() => {setIsModalOpen(true)}}/> 
        }
        <FullScreen onClick={() => {setIsModalOpen(true)}}/>
    <Modal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}}>
      <ImgModal questionID={questionID} src={'/images/' + image} alt={image.slice(0, image.length - 4)}/> 
      { questionID === 20 &&
          <ImgLg src={'/images/close-your-eyes-desktop.png'} alt={image.slice(0, image.length - 4)}/>  
        }
    </Modal>
  <LoaderDualRing visible={isLoading}/>
    </ImageContainer>
  );
};

export default Image;
