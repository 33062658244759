import { css } from 'styled-components';
import { breakpoints } from './media';

export const hideMd = css`
    @media all and (max-width: ${breakpoints.lg}px) {
        display: none;
    }
`;
export const showSm = css`
    @media all and (min-width: ${breakpoints.md}px) {
        display: none;
    }
`;

export const hideLg = css`
    @media all and (min-width: ${breakpoints.lg}px) {
        display: none;
    }
    @media all and (max-width: ${breakpoints.lg -1}px) {
        display: block;
    }
`;

export const hideXl = css`
    @media all and (min-width: ${breakpoints.lg + 1}px) {
        display: none;
    }
`;

export const showXl = css`
    @media all and (min-width: ${breakpoints.lg + 1}px) {
        display: block;
    }
    @media all and (max-width: ${breakpoints.lg}px) {
        display: none;
    }
`;

export const showLg = css`
    @media all and (min-width: ${breakpoints.lg}px) {
        display: block;
    }
    @media all and (max-width: ${breakpoints.lg - 1}px) {
        display: none;
    }
`;