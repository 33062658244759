export const colors = {
    background: '#ebedf4',
    white: '#fff',
    textPrimary: '#303030',
    textSecondary: '#65758b',
    textTetriary: '#808e9f',
    colorPrimary: '#17bf9e',
    backgroundLight: '#edeff7',
    backgroundDark: '#4b5562'
}

export const borderRadius = {
    md: '6px',
    lg: '10px'
};

export const fontSizes = {
    sm: '',
    md: '',
    lg: '',
    xl: '',
    xxl: ''
};

export const fontWeights = {
    base: '400',
    semiBold: '600',
    bold: '700'
}

