import React from 'react';
import styled from 'styled-components';
import { borderRadius } from '../styles/variables';
import media from '../styles/media';

const ModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: ${props => props.isOpen ? '1' : '0'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};

  transition: opacity .3s ease;
`;

const ModalContent = styled.div`
  width: 60%;
  min-width: 320px;
  background-color: white;
  border-radius: ${borderRadius.md};
  height: ${props=> props.small ? '50%' : '70%'};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  ${media.lg`
    width: ${props => props.small && '610px'};
    height: ${props => props.small && '600px'};
  `};

  ${media.lg1`
    width: ${props => props.small && '762px'};
    height: ${props => props.small && '654px'};
    @media all and (max-height: 810px) {
      height: 80%;
    }
  `};
`;

const ModalImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Close = styled.div`
  width: 100%;
  height: 40px;
  background-image: url('/images/close.svg');
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 10px;
  margin-left: -20px;
  cursor: pointer;
`;

const Modal = ({ isOpen, ...props }) => {
  return (
    <>
        <ModalContainer isOpen={isOpen}>
          <ModalContent small={props.small}>
            <Close onClick={props.onClose}/>
            <ModalImage>{props.children}</ModalImage>
          </ModalContent>
        </ModalContainer>
    </>
  );
};

export default Modal;
