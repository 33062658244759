import React, { useState } from 'react';
import {
  Answers,
  QuestionText,
  PreparationInstruction,
} from '../questionStyles';
import {
  WelcomeContainer,
  WelcomeAnswersXL,
  ContinueButton,
} from './preparationStyles';
import { Button, Arrow } from '../../styles/components';
import { Checkbox } from '../checklistStyles';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import media from '../../styles/media';

const ListPointer = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${colors.colorPrimary};
  box-sizing: border-box;
  margin-right: 6px;
  display: inline-block;
  ${media.xl`
        margin-bottom: 2px;
    `};
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const SubListItem = styled.span`
  display: inline-block;
  margin-left: 20px !important;
  margin-bottom: 8px;
  ${media.md`
    margin-left: 35px !important;
  `}
  ${media.lg1`
    margin-bottom: 15px;
  `}
`;

const ListItem = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  ${media.lg1`
    margin-bottom: 15px;
  `}
`;

const GuidelineInstruction = styled(PreparationInstruction)`
  > span {
    margin-left: 10px;
  }
`;

const GuideContainer = styled(WelcomeContainer)`
  ${media.fullHD`
    height: 890px;
  `};
`;

const GuidelineAnswers = styled(Answers)`
  @media all and (max-height: 759px) and (max-width: 768px) {
    position: relative;
    display: block;
    margin-top: 30px;
    margin-left: -16px;
    width: 100%;
  }
`;

const Guideline = ({ id, ...props }) => {
  const [confirmationChecked, setConfirmationChecked] = useState(false);

  return (
    <>
      <GuideContainer>
        <div>
          <QuestionText>MCIDET Guidelines</QuestionText>
          <GuidelineInstruction as="div">
            The maximum score obtainable is 30, but please note the way the you
            conduct the test can influence the final score. For this test to be
            of diagnostic value it is important to follow the guidelines below:{' '}
            <br /> <br />
            <ListItem>
              <ListPointer /> You need to sitting{' '}
              <span style={{ color: colors.colorPrimary }}>
                close to the testee
              </span>{' '}
              (the person you are going test)
            </ListItem>
            <ListItem>
              <ListPointer /> <BoldText>Do not rush.</BoldText> Conduct the test
              at pace of the testee
            </ListItem>
            <ListItem>
              <ListPointer /> <BoldText>Be objective:</BoldText> conduct the
              test in a serious and professional manner
            </ListItem>
            <ListItem>
              <ListPointer /> <BoldText>Important:</BoldText> Do not help or
              coach the testee to get the correct answer
            </ListItem>
            <ListItem>
              <ListPointer /> Accept the testee’s{' '}
              <span style={{ color: colors.colorPrimary }}>first answer</span>{' '}
              as correct or incorrect
            </ListItem>
            <ListItem>
              <ListPointer /> Before you start the test make sure you:
            </ListItem>
            <SubListItem>
              <BoldText>a.</BoldText> Make sure you understand each question and
              what counts as a correct answer before asking the testee to
              respond.
            </SubListItem>
            <br />
            <SubListItem>
              <BoldText>b.</BoldText> Have the necessary preparatory ‘items’
              ready (follow the instructionafter press Continue)
            </SubListItem>
            <br />
            <br />
            <Checkbox>
              <input
                name="chkBox"
                type="checkbox"
                onChange={() => setConfirmationChecked(!confirmationChecked)}
              />
              <span></span>{' '}
              <div style={{ marginLeft: '10px', display: 'inline' }}>
                I confirm that I’ve read the guidelines
              </div>
            </Checkbox>
          </GuidelineInstruction>
          <WelcomeAnswersXL>
            <ContinueButton
              correct
              full
              onClick={() => props.continue()}
              disabled={!confirmationChecked}
            >
              <span>Continue</span> <Arrow white />
            </ContinueButton>
          </WelcomeAnswersXL>
        </div>
      </GuideContainer>
      <GuidelineAnswers>
        <Button
          correct
          full
          onClick={() => props.continue()}
          disabled={!confirmationChecked}
        >
          <span>Continue</span> <Arrow />
        </Button>
      </GuidelineAnswers>
    </>
  );
};

export default Guideline;
