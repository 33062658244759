import { createGlobalStyle } from 'styled-components';
import { colors } from './styles/variables';
import media from './styles/media';

const GlobalStyles = createGlobalStyle`
    body, html {
        margin: 0;
        background-color: ${colors.background};
        height: 100%;
    }
    p {
        margin: 0;
    }
    * {
        font-family: 'Nunito Sans', sans-serif; 
        font-weight: 400;
    }
    #root {
        ${media.lg1`
            height: 100%;
        `}
    }
`;

export default GlobalStyles;
