import React from 'react';
import { Answers } from '../questionStyles';
import {
  WelcomeContainer,
  WelcomeHeader,
  WelcomeText,
  WelcomeTip,
  WelcomeTipXL,
  Illustration,
  WelcomeAnswersXL,
  ContinueButton,
} from './preparationStyles';
import { Img, Button, Arrow, Container } from '../../styles/components';
import styled from 'styled-components';
import { colors, fontWeights } from '../../styles/variables';
import media from '../../styles/media';

const Header = styled(Container)`
  padding: 9px;
  text-align: center;
  color: ${colors.textPrimary};
  font-weight: ${fontWeights.semiBold};
  font-size: 16px;

  ${media.md`
  font-size: 18px;
  padding: 12px;
  `}
  ${media.lg1`
  display: none;
  `}
`;

const AnswersContainerXL = styled(WelcomeAnswersXL)`
  ${media.lg1`
min-height: 50px !important;
  height: 64px!important;
  position: relative;
  width: 100% !important;
  max-width: 100% !important;
`}
`;

const WelcomeAnswers = styled(Answers)`
  @media all and (max-height: 750px) and (max-width: 768px) {
    position: relative;
    display: block;
    margin-top: 30px;
    margin-left: -16px;
    width: 100%;
  }
`;

const IllustrationDesktop = styled(Illustration)`
  width: 50%;
  position: relative;
  min-height: 400px !important;
  > img {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 70%;
  }
  @media all and (max-width: 1024px) {
    display: none;
  }
`;

const WelcomeContainerCustom = styled.div`
  max-width: 100% !important;
  ${media.lg1`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `};
`;

const Welcome = ({ id, ...props }) => {
  return (
    <>
      <Header as="header">
        Mild Cognitive Impairment and Dementia Enquiry Test (MCIDET)
      </Header>
      <WelcomeContainer style={{ position: 'relative', minHeight: '500px' }}>
        <WelcomeContainerCustom style={{ minHeight: '440px' }}>
          <div>
            <Illustration>
              <Img src="/images/illustration.svg" />
            </Illustration>
            <div>
              <WelcomeHeader as="h1">
                Welcome to the MCIDET <span>test</span>
              </WelcomeHeader>
              <WelcomeText>
                The Mild Cognitive Impairment and Dementia Enquiry Test is&nbsp;
                based on&nbsp;a&nbsp;30-point questionnaire that is&nbsp;used
                extensively in&nbsp;clinical and&nbsp;research settings
                to&nbsp;measure cognitive impairment.{' '}
                <span>Estimation&nbsp;time:&nbsp;5-10&nbsp;min</span>
              </WelcomeText>
              <WelcomeTipXL>
                The test is free. After answering all questions, the&nbsp;result
                will be&nbsp;displayed.
              </WelcomeTipXL>
            </div>
          </div>
          <IllustrationDesktop>
            <Img src="/images/illustration.svg" />
          </IllustrationDesktop>
        </WelcomeContainerCustom>
        <AnswersContainerXL>
          <ContinueButton
            style={{
              position: 'absolute',
              left: '75%',
              bottom: '0',
              transform: 'translateX(-50%)',
              width: '320px',
            }}
            correct
            full
            onClick={() => props.continue()}
          >
            <span>Continue</span> <Arrow white />
          </ContinueButton>
        </AnswersContainerXL>
      </WelcomeContainer>
      <WelcomeTip>
        The test is free. After answering all questions, the&nbsp;result will
        be&nbsp;displayed.
      </WelcomeTip>
      <WelcomeAnswers>
        <Button correct full onClick={() => props.continue()}>
          <span>Continue</span> <Arrow />
        </Button>
      </WelcomeAnswers>
    </>
  );
};

export default Welcome;
