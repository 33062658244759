import React from 'react';
import GlobalStyles from './globalStyles';
import styled from 'styled-components';
import AppContextProvider from './services/appContext';
import Question from './components/question';
import media from './styles/media';
import Preparations from './components/preparations/preparations';
import Result from './components/summary/result';

const PageContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media all and (min-width: 1025px) and (max-width: 1600px) {
    max-width: 1000px;
  }

  ${media.md`
    padding: 32px 40px 0px 40px;
  `}

  ${media.lg`
    padding: 24px 64px 0px 64px;
  `};

  ${media.lg1`
    height: 95%;
    > div {
      width: 100%;
    }
  `};
`;

function App() {
  return (
    <AppContextProvider>
      <PageContainer>
        <div>
          <Question />
          <Preparations />
          <Result />
        </div>
        <GlobalStyles />
      </PageContainer>
    </AppContextProvider>
  );
}

export default App;
