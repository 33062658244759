const items = [
    'Pen',
    'Watch',
    'Pencil',
    'TV Remote',
    'Wallet',
    'Phone',
    'Coin',
    'Newspaper',
    'Credit card',
    'Key'
];
export default items;
