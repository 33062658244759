import styled from 'styled-components';
import { Container } from '../styles/components';
import { colors, fontWeights } from '../styles/variables';
import media from '../styles/media';
import { hideXl, showXl } from '../styles/mixins';

export const InsideContainer = styled.div`
  ${media.lg`
       max-width: 780px;
       margin: 0 auto;
  `};
  ${media.lg1`
       min-height: 650px;
       position: relative;
  `};
`;

export const QuestionContainer = styled(Container)`
  padding: 16px 24px;
  min-height: 217px;
  box-sizing: border-box;

  ${media.md`
     min-height: 60vh;
     padding: 56px 80px;
  `};
  ${media.lg`
     min-height: 50vh;
     padding: 48px 130px;
     > ${InsideContainer} {
       max-width: 780px;
       margin: 0 auto;
     }
     @media all and (max-height: 810px) {
       padding: 24px 80px;
     }
  `};
  ${media.lg1`
      min-height: 650px;
     > ${InsideContainer} {
       min-height: 650px;
       position: relative;
     }
     @media all and (max-height: 810px) {
      min-height: 500px;
      > ${InsideContainer} {
       min-height: 500px;
      }
     }
  `};
`;

export const Instruction = styled.p`
  color: ${colors.textSecondary};
  font-size: 14px;
  margin-bottom: 8px;
  ${media.md`
      font-size: 18px;
  `};
  ${media.lg1`
      font-size: 14px;
  `};
  ${media.xxl`
      font-size: 18px;
  `};
  ${media.fullHD`
      font-size: 23px;
  `};
`;

export const PreparationInstruction = styled(Instruction)`
  margin-top: 8px;
  padding-right: 10px;
`;

export const QuestionText = styled.p`
  color: ${colors.textPrimary};
  font-size: 20px;
  font-weight: ${fontWeights.semiBold};
  ${media.md`
      font-size: 32px;
  `};
  ${media.lg1`
      font-size: ${(props) => (props.smallFont ? '28px' : '32px')};
  `};
  ${media.xxl`
      font-size: ${(props) => (props.smallFont ? '32px' : '40px')};
  `};
  ${media.fullHD`
      font-size: ${(props) => (props.smallFont ? '38px' : '48px')};
  `};
`;

export const Answers = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;

  ${media.md`
    left: 40px;
    right: 40px;
  `};
  ${media.lg`
    left: 64px;
    right: 64px;
  `};

  ${hideXl};
  ${media.md`
    @media all and (max-height: 825px) and (max-width: 1024px) {
      position: relative;
      display: block;
      margin-top: 30px;
      margin-left: -40px;
      width: 100%;
    };
    @media all and (max-height: 825px) and (max-width: 1024px) and (min-width: 1024px) {
      margin-left: -62px;
    };
  `};

  @media all and (max-height: 590px) and (max-width: 768px) {
    position: relative;
    display: block;
    margin-top: 30px;
    margin-left: -16px;
    width: 100%;
  }
`;

export const AnswersXL = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;

  ${media.lg`
    left: 0px;
    right: 0px;
    bottom: ${(props) => props.marginBottom || '0px'};
    position: absolute;
  `};

  ${showXl};

  @media all and (max-height: 810px) {
    margin-top: 20px;
    position: ${(props) => props.isImage && 'relative'};
  }
`;

export const View = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Tip = styled(Container)`
  background-color: ${(props) =>
    props.white ? colors.white : colors.colorPrimary};
  color: ${(props) => (props.white ? colors.textSecondary : colors.white)};
  font-size: 14px;
  padding: 20px 24px;
  ${media.md`
      font-size: 18px;
      padding: 20px 80px;
  `};

  ${hideXl};
`;

export const TipXL = styled(Tip)`
  background-color: transparent;
  color: ${colors.textSecondary};
  border: 1px solid ${colors.colorPrimary};
  margin-top: 32px;

  ${showXl}

  ${media.lg`
     padding: 16px 11px 17px 24px;
     font-size: 14px
  `}
  ${media.xxl`
      font-size: 18px;
  `};
  ${media.fullHD`
      font-size: 23px;
  `};
`;
