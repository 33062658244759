import React, { useState, useContext } from 'react';
import {
  Answers,
  QuestionText,
  PreparationInstruction
} from '../questionStyles';
import {
  WelcomeContainer,
  WelcomeAnswersXL,
  ContinueButton
} from './preparationStyles';
import { Button, Arrow } from '../../styles/components';
import styled from 'styled-components';
import items from '../../consts/items';
import { colors } from '../../styles/variables';
import { hideXl, showXl } from '../../styles/mixins';
import media from '../../styles/media';
import { AppContext } from '../../services/appContext';

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  ${hideXl}
  ${media.lg`
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `};
`;

const Item = styled(Button)`
  &,
  &:first-of-type {
    margin-right: 0;
  }
  width: 100%;
  background-color: ${props =>
    props.choosen ? colors.colorPrimary : colors.white};
  color: ${props => (props.choosen ? colors.white : colors.textSecondary)};
  border-color: ${props =>
    props.choosen ? colors.colorPrimary : colors.white};
  cursor: ${props =>
    !props.isDisabled && !props.choosen ? 'default' : 'pointer'};
    ${media.lg`
      height: 120px;
    `};
`;

const ItemContainerXL = styled(ItemContainer)`
  ${showXl};

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 32px;
  ${Item} {
    border: 1px solid ${colors.colorPrimary};
  }
  ${media.lg1`
    display: grid;
  `}
`;

const ItemsList = ({ id, ...props }) => {
  const { saveItems } = useContext(AppContext);
  const [choosenItems, setChoosenItems] = useState([]);

  const chooseItem = item => {
    const modifiedItems = [...choosenItems];
    const itemIndex = modifiedItems.indexOf(item);
    if (itemIndex > -1) {
      modifiedItems.splice(itemIndex, 1);
      setChoosenItems(modifiedItems);
    } else if (modifiedItems.length < 2) {
      modifiedItems.push(item);
      setChoosenItems(modifiedItems);
    }
  };

  const isItemChoosen = item => {
    const a = choosenItems.indexOf(item) > -1;
    return a;
  };

  const saveItemsAndContinue = () => {
    saveItems(choosenItems);
    props.continue();
  };

  const isButtonDisabled = () => choosenItems.length < 2;

  return (
    <>
      <WelcomeContainer>
        <div>
          <QuestionText>Preparation</QuestionText>
          <PreparationInstruction>
            In the room you are in, please make sure you have any TWO of the
            items below with you. You will need them for a test. Tap the two
            items you will use.
          </PreparationInstruction>
          <ItemContainerXL>
            {items.map(item => {
              return (
                <Item
                  choosen={isItemChoosen(item)}
                  key={item}
                  onClick={() => chooseItem(item)}
                  isDisabled={isButtonDisabled()}
                >
                  {item}
                </Item>
              );
            })}
          </ItemContainerXL>
          <WelcomeAnswersXL>
            <ContinueButton
              correct
              full
              disabled={isButtonDisabled()}
              onClick={() => saveItemsAndContinue()}
            >
              <span>Continue</span> <Arrow white />
            </ContinueButton>
          </WelcomeAnswersXL>
        </div>
      </WelcomeContainer>
      <ItemContainer>
        {items.map(item => {
          return (
            <Item
              choosen={isItemChoosen(item)}
              key={item}
              isDisabled={isButtonDisabled()}
              onClick={() => chooseItem(item)}
            >
              {item}
            </Item>
          );
        })}
      </ItemContainer>
      <Answers>
        <Button
          correct
          full
          disabled={isButtonDisabled()}
          onClick={() => saveItemsAndContinue()}
        >
          <span>Continue</span> <Arrow />
        </Button>
      </Answers>
    </>
  );
};

export default ItemsList;
