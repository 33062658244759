import React, { createContext, useState, useCallback, useEffect } from 'react';
import { questionsNumber } from '../consts/constants';
import Modal from '../components/modal';
import RestartModal from '../components/restartModal';

export const AppContext = createContext([]);

export const views = {
    preparation: 'PREPARATION',
    questions: 'QUESTIONS',
    SUMMARY: 'SUMMARY'
}

const AppContextProvider = props => {

  const [view, setView] = useState(localStorage.getItem('view') || views.preparation)

  const [currentQuestion, setCurrentQuestion] = useState(1);

  const [ points, setPoints ] = useState(0);
  const [ items, setItems ] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const itemsFromStorage = localStorage.getItem('items');
    if(itemsFromStorage)
      setItems(itemsFromStorage.split(','));

    const questionFromStorage = Number(localStorage.getItem('question'));
    if(questionFromStorage)
      setCurrentQuestion(questionFromStorage);

    const pointsFromStorage = Number(localStorage.getItem('points'));
    if(pointsFromStorage >= 0)
        setPoints(pointsFromStorage);

    const viewFromStorage = localStorage.getItem('view');
      if(viewFromStorage) {
        setView(viewFromStorage);
        if(viewFromStorage !== views.preparation)
          setIsModalOpen(true);
      }
  }, [])

  const goToNextQuestion = useCallback(
    (newPoints) => {
        localStorage.setItem('points', points + newPoints); 
        setPoints(points + newPoints);
        if(currentQuestion + 1 > questionsNumber) {
          setView(views.SUMMARY);
          localStorage.setItem('view', views.SUMMARY);
        } else {
            localStorage.setItem('question', currentQuestion + 1)
            setCurrentQuestion(currentQuestion + 1);
        }
    },
      [currentQuestion, points],
  )

  const goToQuestions = () => {
    setView(views.questions)
    localStorage.setItem('view', views.questions);
  }

  const saveItems = (items) => {
    localStorage.setItem('items', items)
    setItems(items);
  }

  const restart = () => {
    localStorage.removeItem('items');
    setItems([]);
    localStorage.removeItem('view');
    setView(views.preparation);
    localStorage.removeItem('points');
    setPoints(0);
    localStorage.removeItem('question');
    setCurrentQuestion(1);
  }

  const state = { view, currentQuestion, goToNextQuestion, points, goToQuestions, items, saveItems, restart };

  return (
    <AppContext.Provider value={state}>
      {props.children}
      <Modal small isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <RestartModal closeModal={() => setIsModalOpen(false)} restart={restart}/>
      </Modal>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
