import React, { useContext, useState, useEffect } from 'react';
import QuestionHeader from './questionHeader';
import { getInstruction } from '../helpers/instructions';
import { AppContext, views } from '../services/appContext';
import {
  View,
  QuestionContainer,
  Instruction,
  QuestionText,
  Answers,
  AnswersXL,
  Tip,
  TipXL,
  InsideContainer,
} from './questionStyles';
import QuestionPreparation from './questionPreparation';
import questions, { questionsType } from '../consts/questions';
import Image from './image';
import { ListContainer, Answer, Checkbox, Label } from './checklistStyles';
import { Button, ButtonXL } from '../styles/components';
import { colors } from '../styles/variables';
import styled from 'styled-components';

const currentViews = {
  preparation: 'PREPARATION',
  question: 'QUESTION',
};

const Item = styled.span`
  font-weight: inherit;
  color: ${colors.colorPrimary};
  text-transform: lowercase;
`;

const Question = () => {
  const { view, currentQuestion, goToNextQuestion, items } = useContext(
    AppContext
  );
  const [currentView, setCurrentView] = useState(null);
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    const currQuestion = questions.find((el) => el.id === currentQuestion);
    if (!!currQuestion) {
      const view = !!currQuestion.preparation
        ? currentViews.preparation
        : currentViews.question;
      setCurrentView(view);
      setQuestion(currQuestion);
    }
  }, [currentQuestion, setQuestion]);

  const submit = (correct) => {
    let points = question.avaiablePoints;
    if (question.type === questionsType.checkboxes) {
      const checkedBoxes = document.querySelectorAll(
        'input[name=chkBox]:checked'
      );
      points = checkedBoxes.length;
    }
    goToNextQuestion(correct ? points : 0);
  };

  const changeViewToQuestion = (type) => {
    if (question.id === 14) {
      if (type === 'WORDS') {
        setQuestion({
          ...question,
          question: question.question[1],
          answers: question.answers[1],
        });
      } else if (type === 'NUMBER') {
        setQuestion({
          ...question,
          question: question.question[0],
          answers: question.answers[0],
        });
      }
    }
    setCurrentView(currentViews.question);
  };

  return (
    <>
      {view === views.questions &&
        currentView &&
        (currentView === currentViews.preparation ? (
          <QuestionPreparation
            question={question}
            continue={(e) => {
              changeViewToQuestion(e);
            }}
          />
        ) : (
          <View>
            <QuestionContainer>
              <InsideContainer>
                <QuestionHeader id={question.id} />
                <Instruction>{getInstruction(question)}</Instruction>
                {question.id === 21 ? (
                  <QuestionText
                    dangerouslySetInnerHTML={{ __html: question.question }}
                  ></QuestionText>
                ) : (
                  <QuestionText
                    smallFont={question.id === 14 || question.id === 22}
                  >
                    {question.question}
                    {(question.id === 16 || question.id === 17) && (
                      <Item> ({items[question.id % 16]})</Item>
                    )}
                  </QuestionText>
                )}

                {question.type === questionsType.image && (
                  <Image image={question.image} questionID={question.id} />
                )}

                {question.type === questionsType.checkboxes && (
                  <ListContainer>
                    {question.answers.map((answer) => {
                      return (
                        <Answer key={answer}>
                          <Checkbox>
                            <input name="chkBox" type="checkbox" />
                            <span></span>
                          </Checkbox>
                          <Label htmlFor={answer}>{answer}</Label>
                        </Answer>
                      );
                    })}
                  </ListContainer>
                )}
                {question.answerTip &&
                  (question.id === 21 ? (
                    <TipXL
                      dangerouslySetInnerHTML={{ __html: question.answerTip() }}
                    ></TipXL>
                  ) : (
                    <TipXL>{question.answerTip()}</TipXL>
                  ))}

                <AnswersXL
                  marginBottom={question.id === 22 && '-15px'}
                  isImage={question.type === questionsType.image}
                >
                  {question.type === questionsType.checkboxes ? (
                    <ButtonXL
                      id={`next-question-${question.id}`}
                      full
                      onClick={() => submit(true)}
                    >
                      Next question
                    </ButtonXL>
                  ) : (
                    <>
                      <ButtonXL
                        id={`incorrect-${question.id}`}
                        dark
                        onClick={() => submit(false)}
                      >
                        Incorrect
                      </ButtonXL>
                      <ButtonXL
                        id={`correct-${question.id}`}
                        onClick={() => submit(true)}
                      >
                        Correct
                      </ButtonXL>
                    </>
                  )}
                </AnswersXL>
              </InsideContainer>
            </QuestionContainer>
            {question.answerTip &&
              (question.id === 21 ? (
                <Tip
                  dangerouslySetInnerHTML={{ __html: question.answerTip() }}
                  white
                ></Tip>
              ) : (
                <Tip>{question.answerTip()}</Tip>
              ))}

            <Answers>
              {question.type === questionsType.checkboxes ? (
                <Button
                  id={`next-question-${question.id}`}
                  correct
                  full
                  onClick={() => submit(true)}
                >
                  Next question
                </Button>
              ) : (
                <>
                  <Button
                    id={`incorrect-${question.id}`}
                    onClick={() => submit(false)}
                  >
                    Incorrect
                  </Button>
                  <Button
                    id={`correct-${question.id}`}
                    correct
                    onClick={() => submit(true)}
                  >
                    Correct
                  </Button>
                </>
              )}
            </Answers>
          </View>
        ))}
    </>
  );
};

export default Question;
