import React from 'react';
import styled from 'styled-components';
import { Button, FullButton, Container } from '../styles/components';

import { QuestionText, PreparationInstruction } from './questionStyles';
import media from '../styles/media';


const RestartContainer = styled(Container)`
    padding: 0 24px 16px 24px;
    margin-top: 10px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    ${media.md`
      padding: 0 56px 40px 56px; 
      margin-top: 40px;
    `};
    ${media.lg`
      padding: 0 64px 46px 64px; 
      margin-top: 60px;
    `};
`;

const RestartModal = ({ closeModal, restart, ...props }) => {

  return (
    <RestartContainer>
      <div>
        <QuestionText>Restore the session</QuestionText>
        <PreparationInstruction>We’ve detected that you are in the process of completing the test. Tell us whether you want to continue or start the test from the beginning.</PreparationInstruction>
        </div>
        <div>
          <Button onClick={() => {closeModal(); restart()}}>Start again</Button>
          <FullButton onClick={() => closeModal()}>Continue</FullButton>
        </div>
    </RestartContainer>
  );
};

export default RestartModal;
