import React, { useState, useContext, useEffect } from 'react';
import Welcome from './welcome';
import ItemsList from './itemsList';
import { AppContext, views } from '../../services/appContext';
import Guideline from './guideline';

const preparationViews = {
  welcome: 'WELCOME',
  itemsList: 'ITEMS_LIST',
  neededItems: 'NEEDED_ITEMS',
};

const Preparations = () => {
  const { view, goToQuestions } = useContext(AppContext);
  const [currentView, setCurrentView] = useState(preparationViews.welcome);

  useEffect(() => {
    if (view === views.preparation) setCurrentView(preparationViews.welcome);
  }, [view]);
  const goToNextStep = () => {
    switch (currentView) {
      case preparationViews.welcome:
        setCurrentView(preparationViews.itemsList);
        break;
      case preparationViews.itemsList:
        setCurrentView(preparationViews.neededItems);
        break;
      default:
        goToQuestions();
    }
  };
  const getComponentFromCurrentView = () => {
    switch (currentView) {
      case preparationViews.welcome:
        return <Welcome continue={goToNextStep} />;
      case preparationViews.itemsList:
        return <ItemsList continue={goToNextStep} />;
      default:
        return <Guideline continue={goToNextStep} />;
    }
  };
  return <>{view === views.preparation && getComponentFromCurrentView()}</>;
};

export default Preparations;
