import { css } from 'styled-components';

export const breakpoints = {
  xl: 1280,
  lg: 1024,
  lg1: 1025,
  md: 768,
  sm: 320,
  fullHD: 1920,
  xxl: 1400,
};

export default Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
