import React, { useContext } from 'react';
import {
  QuestionContainer,
  QuestionText,
  Instruction,
} from '../questionStyles';
import { AppContext, views } from '../../services/appContext';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import { Button } from '../../styles/components';
import media from '../../styles/media';

const ResultTip = styled(Instruction)`
  color: ${colors.colorPrimary};
  ${media.lg1`
    margin-bottom: 32px;
  `};
`;

const LinksContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;
const LinkButton = styled(Button)`
  display: inline-block;
  width: 228px;
  height: 68px;
  background-image: ${(props) => `url(images/${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0;
  margin-right: 5px;
  ${media.md`
    margin-right: 44px !important;
  `};
  ${media.lg1`
    margin-right: 64px !important;
  `};
`;
const ShareLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  ${media.lg1`
  margin: 3vh auto;
  `};

  @media only screen and (min-width: 1024px) {
    display: ${(props) => props.onlyMobile && 'none'};
  }
`;

const NRImage = styled.img`
  margin: 20px auto;
  ${media.lg1`
    display: none;
  `};
`;

const Result = () => {
  const fbShare = () => {
    const image = 'https://wwww.dementiatest.online/images/fb-image.jpg';
    const title = 'Dementia test';
    const descr =
      "Dementia test is an online tool that allows checking your relative's mental state. It's the same test doctors use in their offices. If you worry about your parent's health, try it now!";
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=https://www.dementiatest.online/&title=' +
        title +
        '&description=' +
        descr +
        '&picture=' +
        image,
      'pop',
      'top=' +
        100 +
        ',left=' +
        100 +
        ',toolbar=0,status=0,width=' +
        520 +
        ',height=' +
        350
    );
  };

  const { points, view } = useContext(AppContext);
  return (
    <>
      {view === views.SUMMARY && (
        <QuestionContainer style={{ width: '100%' }}>
          {points > 24 && (
            <>
              <QuestionText style={{ marginBottom: '20px' }}>
                Congratulations!
              </QuestionText>
              <Instruction style={{ marginBottom: '20px' }}>
                The testee scored {points} out of 30 points. It’s within the
                norm. This score is high enough, so they don’t need to worry
                about their cognitive health - it’s all good.
              </Instruction>
            </>
          )}

          {points < 25 && (
            <>
              <QuestionText style={{ marginBottom: '20px' }}>
                The testee scored {points} out of 30 points.
              </QuestionText>
              {points < 18 ? (
                <Instruction style={{ marginBottom: '8px' }}>
                  This results might suggest that the testee has started to
                  develop a severe cognitive impairment (MCI). It can be
                  dementia or other neurological diseases. We strongly suggest
                  the testee visit their doctor or clinical psychologist and ask
                  for their advice.
                </Instruction>
              ) : (
                <Instruction style={{ marginBottom: '8px' }}>
                  This results might suggest that the testee has started to
                  develop a mild cognitive impairment (MCI). It can be the start
                  of dementia or other neurological diseases. We strongly
                  suggest the testee visit their doctor or clinical psychologist
                  and ask for their advice.
                </Instruction>
              )}
            </>
          )}
          <Instruction style={{ marginBottom: '20px' }}>
            If you find this test useful, please share it with your friends.
          </Instruction>
          <ShareLink>
            <LinkButton
              style={{ width: '320px' }}
              image="share-fb.png"
              onClick={fbShare}
            />
          </ShareLink>
          <a
            href="https://wa.me/?text=Dementia test is an online tool that allows checking your relative's mental state. It's the same test doctors use in their offices. If you worry about your parent's health, try it now! https://dementiatest.online"
            data-action="share/whatsapp/share"
          >
            <ShareLink onlyMobile={true}>
              <LinkButton
                style={{ width: '320px' }}
                image="whatsapp-link.png"
              />
            </ShareLink>
          </a>
          <Instruction style={{ marginBottom: '20px' }}>
            They can also download and use the{' '}
            <span style={{ color: '#7376ff' }}>Natural Reminders (NR)</span> app
            to support their memory for tasks and check how their memory for
            tasks is currently performing and to continue monitoring it changes
            over the coming months and years.
          </Instruction>
          <NRImage
            style={{ width: '100%' }}
            alt="natural-reminder-app"
            src="/images/nr-app.png"
          />
          <ResultTip>
            Natural Reminders app is free to download and use from Google Play
            and Apple’s App Store.
          </ResultTip>
          <LinksContainer>
            <LinkButton
              image="android-link.png"
              as="a"
              href="https://play.google.com/store/apps/details?id=com.sentiencesoftware.naturalreminders"
              target="_blank"
            />
            <LinkButton
              image="ios-link.png"
              as="a"
              href="https://apps.apple.com/us/app/natural-reminders-nr/id1338904909"
              target="_blank"
            />
          </LinksContainer>
        </QuestionContainer>
      )}
    </>
  );
};

export default Result;
