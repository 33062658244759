import styled from 'styled-components';
import { colors } from '../styles/variables';
import media from '../styles/media';

export const ListContainer = styled.div`
  margin-top: 32px;
`;

export const Answer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 12px;
  margin-left: 2px;
  position: relative;
  > input {
    position: absolute;
    z-index: -9999;
    &:checked + span {
      background: url('/images/check.svg'),
        linear-gradient(${colors.colorPrimary}, ${colors.colorPrimary});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  > span {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${colors.backgroundLight};
    cursor: pointer;

    ${media.md`
      width: 28px;
      height: 28px;
    `};
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${colors.textSecondary};

  ${media.md`
      font-size: 18px;
  `};
  ${media.lg1`
      font-size: 14px;
  `};
  ${media.xxl`
      font-size: 16px;
  `};
  ${media.fullHD`
      font-size: 20px;
  `};
`;
