import { questionsType } from '../consts/questions';

export const getInstruction = question => {
  if (!!question.instruction)
    return question.instruction;

  switch (question.type) {
    case questionsType.simple:
      return 'Ask testee the question:';
    case questionsType.image:
      return 'Show testee the image and ask:';
    case questionsType.checkboxes:
        return 'Ask testee to follow this instruction and mark their answers.';
    case questionsType.instruction:
        return 'Ask testee to follow these instructions:';
    default:
      return '';
  }
};
