import {
  getisSunday,
  getHasYear7,
  getHasMonthQ,
} from '../helpers/correctAnswers';

export const questionsType = {
  simple: 'SIMPLE',
  image: 'IMAGE',
  instruction: 'INSTRUCTION',
  custom: 'CUSTOM',
  checkboxes: 'CHECKBOXES',
};

export const question14Types = {
  number: 'Number',
  word: 'Word',
};

const questions = [
  {
    id: 1,
    type: questionsType.simple,
    question: 'Is it Morning now?',
    avaiablePoints: 1,
  },
  {
    id: 2,
    type: questionsType.simple,
    question: 'Is today Sunday?',
    answerTip: () => getisSunday(),
    avaiablePoints: 1,
  },
  {
    id: 3,
    type: questionsType.simple,
    question: 'Do children usually go school today?',
    avaiablePoints: 1,
  },
  {
    id: 4,
    type: questionsType.simple,
    question:
      'The year that we are in right now – does it have the number ‘7’ in it?',
    answerTip: () => getHasYear7(),
    avaiablePoints: 1,
  },
  {
    id: 5,
    type: questionsType.simple,
    question: 'The Month we are in right now – does have the letter ‘Q’ in it?',
    answerTip: () => getHasMonthQ(),
    avaiablePoints: 1,
  },
  {
    id: 6,
    type: questionsType.simple,
    question: 'Are you in the countryside or city right now?',
    avaiablePoints: 1,
  },
  {
    id: 7,
    type: questionsType.simple,
    question: 'Are you living in an apartment or a house?',
    avaiablePoints: 1,
  },
  {
    id: 8,
    type: questionsType.simple,
    question:
      'If you looked out window of the room you are in right now, is there a street lamppost there?',
    avaiablePoints: 1,
  },
  {
    id: 9,
    type: questionsType.simple,
    question: 'Is there a photo of you or anyone in your family in this room?',
    avaiablePoints: 1,
  },
  {
    id: 10,
    type: questionsType.simple,
    question: 'Is your bedroom on the ground floor, 2nd floor or, higher?',
    avaiablePoints: 1,
  },
  {
    id: 11,
    preparation: {
      number: '11-13',
      content:
        'In the next 3 questions, we will display 3 images of the objects. Show them to the testee on the phone or laptop and ask them to name it.',
    },
    type: questionsType.image,
    question: 'What image is this?',
    image: 'banana.png',
    avaiablePoints: 1,
  },
  {
    id: 12,
    type: questionsType.image,
    question: 'What image is this?',
    image: 'car.png',
    avaiablePoints: 1,
  },
  {
    id: 13,
    type: questionsType.image,
    question: 'What image is this?',
    image: 'horse.png',
    avaiablePoints: 1,
  },
  {
    id: 14,
    preparation: {
      number: '14',
      content:
        'Please ask the testee if they feel better with the number or the words questions. Then ask them to complete the task and mark their answers.',
      answers: [question14Types.number, question14Types.word],
    },
    type: questionsType.checkboxes,
    question: [
      'Beginning with 80 keep dividing the answer in half until I say stop?',
      'Without including Saturday or Sunday tell me the days of the week backwards starting with Friday?',
    ],
    answers: [
      ['80', '40', '20', '10', '5'],
      ['Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'],
    ],
    avaiablePoints: 5,
  },
  {
    id: 15,
    type: questionsType.checkboxes,
    question: 'Name the three images you were asked to named earlier.',
    answers: ['Banana', 'Horse', 'Car'],
    avaiablePoints: 3,
  },
  {
    id: 16,
    preparation: {
      number: '16-17',
      content:
        'Now you will need the objects you were supposed to prepared earlier. You will show it to the testee and ask “what is this called?”.',
    },
    type: questionsType.simple,
    question: 'What is “this” called?',
    avaiablePoints: 1,
  },
  {
    id: 17,
    type: questionsType.simple,
    question: 'What is “this” called?',
    avaiablePoints: 1,
  },
  {
    id: 18,
    type: questionsType.simple,
    instruction: 'Ask testee to repeat the phrase:',
    question: 'Repeat after me: “One flew over the cookoo’s nest”.',
    answerTip: () => `If there are any mistakes you need to score 
    this “Incorrect”.`,
    avaiablePoints: 1,
  },
  {
    id: 19,
    type: questionsType.checkboxes,
    question: `When I finish speaking, I would like you to:`,
    answers: [
      'Tickle the top of your head with your right hand',
      'Turn your heard left to look behind you',
      'Say “Aahhh” for 5 seconds',
    ],
    answerTip: () => `If they say “Aahhh” for short than 3 or longer than 6 seconds you need to score 
    this “Incorrect”.`,
    avaiablePoints: 3,
  },
  {
    id: 20,
    type: questionsType.instruction,
    instruction: 'Ask testee to read and follow the instruction:',
    question: 'Please sniff 3-times',
    answerTip: () =>
      `If they don’t sniff or sniff less or more than 3-times then mark incorrect`,
    avaiablePoints: 1,
  },
  {
    id: 21,
    type: questionsType.instruction,
    question:
      'Write a sentence about to about a magic <span style="color: #4bca8f; font-weight:inherit">fruit</span> <span style="color: #7376ff; font-weight:inherit">doing</span> something.',
    answerTip: () => `The sentence is correct only if contains a&nbsp;noun and&nbsp;verb.
    Example:&nbsp; The magic <span style="color: #4bca8f; font-weight:inherit">tomato</span><span style="color: #7376ff; font-weight:inherit"> was singing</span> a&nbsp;song.`,
    avaiablePoints: 1,
  },
  {
    id: 22,
    preparation: {
      number: '22',
      content:
        'This task requires to give testee a blank sheet of paper or letter sized paper, and a pen or pencil - that you will give the to testee to draw picture.',
      tip:
        'If you think testee has a problem with reading this on the screen, then redraw it on a big piece of paper and show them.',
    },
    type: questionsType.image,
    instruction: 'Ask testee to follow this instruction:',
    question: 'Please copy this picture.',
    image: 'rhombus.png',
    answerTip: () =>
      `Answer is correct only if the two rhombus intersect has shown to form a 4-sided shape in the centre.`,
    avaiablePoints: 1,
  },
];

export default questions;
