import styled from 'styled-components';
import { colors, borderRadius, fontWeights } from './variables';
import media from './media';

export const Img = styled.img`
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 24px;
  height: 9px;
  margin-bottom: 1.5px;
  margin-left: 5px;
  background-image: ${props => props.white ? `url('/images/white-right-arrow.svg')` : `url('/images/right-arrow.svg')`};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: ${borderRadius.md};
  margin-bottom: 10px;

  ${media.md`
      margin-bottom: 12px;
    `};
  ${media.lg`
      margin-bottom: 16px;
    `};
  ${media.xl`
      border-radius: ${borderRadius.lg};
    `};
`;

export const Button = styled.button`
  height: 48px;
  background-color: ${colors.white};
  box-shadow: none;
  border: 1px solid white;
  width: ${props => (props.full ? '100%' : 'calc(50% - 5px)')};
  color: ${props =>
    props.correct ? colors.colorPrimary : colors.textSecondary};
  font-size: 16px;
  cursor: pointer;
  outline: 0;
  border-radius: ${borderRadius.md};
  transition: opacity .5s ease;

  &:first-of-type {
    margin-right: 10px;
  }

  ${media.md`
      height: 64px;
      font-size: 18px;
    `};

    :disabled {
      opacity: .5;
    }
`;

export const FullButton = styled(Button)`
  background-color: ${colors.colorPrimary};
  border-color: ${colors.colorPrimary};
  color: ${colors.white};
`;

export const ButtonXL = styled(Button)`
  background-color: ${props =>
    props.dark ? colors.backgroundDark : colors.colorPrimary};
  color: ${colors.white};
  font-weight: ${fontWeights.semiBold};
  border-color: ${props =>
    props.dark ? colors.backgroundDark : colors.colorPrimary};

  > span {
    font-size: 18px;
  }
`;
