import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { questionsNumber } from '../consts/constants';
import media from '../styles/media';

const QuestionProgress = styled.p`
  margin: 0;
  color: ${colors.textTetriary};
  font-size: 12px;

  ${media.md`
      font-size: 16px;
  `};
  ${media.md`
      font-size: 20px;
  `};
  ${media.lg1`
      font-size: 14px;
  `};
  ${media.xxl`
      font-size: 16px;
  `};
  ${media.fullHD`
      font-size: 20px;
  `};
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.background};
  position: relative;
  border-radius: 6px;
  margin: 8px 0 24px 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    background-color: ${colors.colorPrimary};
    width: ${(props) => `calc(${props.currentQuestion / 22} * 100%)`};
    height: 100%;
    border-radius: 6px;
  }

  ${media.md`
      margin-bottom: 32px;
  `};
`;
const QuestionHeader = ({ id, ...props }) => {
  return (
    <>
      {!!props.number ? (
        <QuestionProgress>Question {props.number}</QuestionProgress>
      ) : (
        <QuestionProgress>
          Question {id}/{questionsNumber}
        </QuestionProgress>
      )}
      <ProgressBar currentQuestion={id} />
    </>
  );
};

export default QuestionHeader;
