import React from 'react';
import QuestionHeader from './questionHeader';
import {
  View,
  QuestionContainer,
  QuestionText,
  Answers,
  Tip,
  AnswersXL,
  InsideContainer,
  PreparationInstruction,
} from './questionStyles';
import { Button, FullButton, Arrow } from '../styles/components';
import {
  ContinueButton,
  WelcomeAnswersXL,
} from './preparations/preparationStyles';

const QuestionPreparation = ({ question, ...props }) => {
  return (
    <View>
      <QuestionContainer>
        <InsideContainer>
          <QuestionHeader
            id={question.id}
            number={question.preparation.number}
          />
          <QuestionText>Preparation</QuestionText>
          <PreparationInstruction>
            {question.preparation.content}
          </PreparationInstruction>

          {!!question.preparation.answers ? (
            <AnswersXL>
              <FullButton correct onClick={() => props.continue('NUMBER')}>
                Number
              </FullButton>
              <FullButton correct onClick={() => props.continue('WORDS')}>
                Words
              </FullButton>
            </AnswersXL>
          ) : (
            <WelcomeAnswersXL style={{ marginTop: '20vh' }}>
              <ContinueButton correct full onClick={() => props.continue()}>
                <span>Continue</span> <Arrow white />
              </ContinueButton>
            </WelcomeAnswersXL>
          )}
        </InsideContainer>
      </QuestionContainer>
      {question.preparation.tip && <Tip>{question.preparation.tip}</Tip>}

      <Answers>
        {!!question.preparation.answers ? (
          <>
            <FullButton correct onClick={() => props.continue('NUMBER')}>
              Number
            </FullButton>
            <FullButton correct onClick={() => props.continue('WORDS')}>
              Words
            </FullButton>
          </>
        ) : (
          <>
            <Button correct full onClick={() => props.continue()}>
              <span>Continue</span> <Arrow />
            </Button>
          </>
        )}
      </Answers>
    </View>
  );
};

export default QuestionPreparation;
