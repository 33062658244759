import media from '../../styles/media';
import {
  Instruction,
  QuestionText,
  QuestionContainer,
  TipXL,
  Tip,
} from '../questionStyles';
import styled from 'styled-components';
import { ButtonXL } from '../../styles/components';
import { fontWeights } from '../../styles/variables';
import { hideXl, showXl, hideMd } from '../../styles/mixins';

export const WelcomeContainer = styled(QuestionContainer)`
  ${media.md`
        min-height: 300px;
    `}
  ${media.lg1`
  width: 100%;
  padding: 48px 130px;
    min-height: 650px;
        > div {
            min-height: 650px;
            max-width: 780px;
            margin: 0 auto;
            position: relative;
            height: 100%;
        }
        @media all and (max-height: 810px) {
          min-height: 500px;
          > div {
            min-height: 500px;
        }
       
    `};
`;

export const Illustration = styled.div`
  width: 100%;
  max-height: 200px;
  display: flex;
  justify-content: center;
  ${hideXl}
`;

export const WelcomeHeader = styled(QuestionText)`
  margin: 24px auto;
  margin-top: 24px;
  font-size: 24px;
  max-width: 783px;
  > span {
    font-weight: inherit;
    display: none;
  }
  ${media.md`
        text-align: center;
        font-size: 24px;
    `}
  ${media.lg1`
        font-size: 28px;
        text-align: left; 
        margin-top: 0;
    `}
    ${media.xl`
        margin: 0 auto 20px auto;
        > span {
            display: inline;
        }
    `};
    ${media.xxl`
        font-size: 40px
    `};
    ${media.fullHD`
        font-size: 48px
    `};
`;

export const WelcomeText = styled(Instruction)`
  max-width: 783px;
  > span {
    display: block;
    margin-top: 20px;
    ${media.md`
            margin-top: 30px;
    `}
    ${media.lg1`
            margin-top: 40px;
    `}
  }
  ${media.md`
        text-align: center;
        font-size: 18px;
    `}
  ${media.lg1`
        text-align: left;
        font-size: 14px;
        margin: 0 auto;
    `}
    ${media.xl`
      font-size: 18px;
  `};
  ${media.fullHD`
      font-size: 23px;
  `};
`;

export const WelcomeTip = styled(Tip)`
  ${media.md`
        text-align: ${(props) => (props.left ? 'left' : 'center')};
        padding: 20px ${(props) => (props.bigPadding ? '80px' : '45px')};
    `}
  ${media.lg`
        text-align: ${(props) => (props.left ? 'left' : 'center')};
        padding: 20px 80px;
    `}
`;

export const WelcomeTipXL = styled(TipXL)`
  max-width: 783px;
  margin: 0 auto;
  ${showXl}

  margin-top: 32px;
  font-weight: ${fontWeights.base};
`;

export const ContinueButton = styled(ButtonXL)`
  ${hideMd}
  bottom: 15px;
`;

export const WelcomeAnswersXL = styled.div`
  width: 320px;
  right: 0;
  bottom: 0;
  position: absolute;
`;
