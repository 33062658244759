const template = 'Correct answer: ';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

var dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const getisSunday = () => {
  const date = new Date();
  return `${template} ${dayNames[date.getDay()] === 'Sunday' ? 'Yes' : 'No'}`;
};

export const getHasYear7 = () => {
  const date = new Date();
  return `${template} ${date.getFullYear().toString().includes(7) ? 'Yes' : 'No'}`;
};

export const getHasMonthQ = () => {
  const date = new Date();
  return `${template} ${monthNames[date.getMonth()].includes('Q') ? 'Yes' : 'No'}`;
};